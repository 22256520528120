import { renderPdfs } from '@blissbook/ui/lib/pdf/render'
import { useStore } from '@blissbook/ui/util/store'
import { createPortal } from 'react-dom'
import { hot } from 'react-hot-loader/root'
import { ToastContainer } from 'react-toastify'
import {
  resetAutoSignOut,
  setHelpOpen,
  setSignHistoryOpen,
  setSignMoreOpen,
  setSignOpen,
} from './actions'
import { AnnotationsProvider, AnnotationsView } from './annotations'
import { ContactModal } from './contact'
import { PreviewHeader, SignatureHeader } from './header'
import { AutoSignOutModal } from './kiosk'
import { LanguageSelector } from './language'
import { Navigation } from './navigation'
import PrintModal from './print'
import {
  SignHeader,
  SignHistoryModal,
  SignModal,
  SignMoreModal,
} from './signature'
import { initialState } from './state'
import './widgets'
import './wysiwyg'

const { forceSign } = initialState.handbook.acknowledgementForm

const previewHeaderNode = document.getElementById('preview-header')
const signatureHeaderNode = document.getElementById('signature-header')
renderPdfs(document.body)

const Application = () => {
  const {
    autoSignOut,
    isHelpOpen,
    isLanguageOpen,
    isSignatureRequired,
    isSignHistoryOpen,
    isSignOpen,
    isSignMoreOpen,
  } = useStore()

  return (
    <AnnotationsProvider>
      {previewHeaderNode &&
        createPortal(<PreviewHeader />, previewHeaderNode.children[0])}
      {signatureHeaderNode &&
        createPortal(<SignatureHeader />, signatureHeaderNode.children[0])}

      {isSignatureRequired && !forceSign && <SignHeader />}

      <Navigation isLocked={isLanguageOpen || isSignOpen || isSignMoreOpen} />

      {isLanguageOpen && <LanguageSelector />}

      <AnnotationsView />

      <AutoSignOutModal.Animated
        isOpen={autoSignOut}
        onClose={() => resetAutoSignOut()}
      />
      <ContactModal.Animated
        isOpen={isHelpOpen}
        onClose={() => setHelpOpen(false)}
      />
      <PrintModal />
      <SignHistoryModal.Animated
        isOpen={isSignHistoryOpen}
        onClose={() => setSignHistoryOpen(false)}
      />
      <SignModal.Animated
        isOpen={!isLanguageOpen && isSignOpen}
        onClose={() => setSignOpen(false)}
      />
      <SignMoreModal.Animated
        isOpen={isSignMoreOpen}
        onClose={() => setSignMoreOpen(false)}
      />

      <ToastContainer />
    </AnnotationsProvider>
  )
}

export default hot(Application)
