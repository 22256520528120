import { docMediaProviders } from '@blissbook/ui/ckeditor/plugins/Media/MediaProvider'
import throttle from 'lodash/throttle'
import { isDesktop } from '../viewport'
import './parallax-background'
import './parallax'
import './slidilax'

export const renderEffects = () => {
  const $el = $('.blissbook-branded')
  if (isDesktop) {
    $el.find('.background-fixed-parallax').parallaxBackground()
    $el.find('[data-timelineilax]').timelineilax()
    $el.find('[data-parallax]').parallax()
    $el.find('[data-rotateilax]').rotateilax()
    $el.find('[data-slidilax]').slidilax()
    $el.find('[data-opacilax]').opacilax()
    $el.find('[data-scaleilax]').scaleilax()
    $el.find('[data-opacitate]').opacitate()
    $el
      .find(
        '.rw-wysiwyg img[height],.rw-wysiwyg img[width],.ck-content img[width]',
      )
      .fancyWrap()
    $el
      .find('.fancybox')
      .fancybox({ openEffect: 'elastic', closeEffect: 'elastic' })
  }

  // Render oembeds via iframely
  document.querySelectorAll('oembed[url]').forEach((element) => {
    const url = element.getAttribute('url')
    if (!url) return

    const provider = docMediaProviders.find((p) =>
      p.url.some((pattern) => pattern.test(url)),
    )
    if (provider?.html) {
      const match = provider.url
        .map((pattern) => url.match(pattern))
        .find(Boolean)

      if (!match) return

      const iframeHtml = provider.html(match)
      const wrapper = document.createElement('div')
      wrapper.innerHTML = iframeHtml
      element.parentNode.replaceChild(wrapper.firstChild, element)
    } else {
      window.iframely.load(element, url)
    }
  })
}

$.fn.fancyWrap = function () {
  for (const imageEl of this) {
    const parentAnchor = imageEl.closest('a')
    if (parentAnchor) return

    const src = imageEl.getAttribute('src')
    imageEl.outerHTML = `<a href="${src}" class="fancybox">${imageEl.outerHTML}</a>`
  }
}

// Change the opacity
$.fn.opacitate = function () {
  $(this).each(function () {
    let tick = 0
    const delayMs = Number.parseInt($(this).attr('data-opacitate'), 10)
    const $childrenEl = $(this).find('[data-opacitate-phase]')
    setInterval(() => {
      // Update the tick
      tick = tick + 10
      if (tick >= 360) tick = 0

      $childrenEl.each(function () {
        const phase = Number.parseInt($(this).attr('data-opacitate-phase'), 10)
        const phaseDelta = Math.abs(tick - phase)

        let opacity = 0.3
        if (phaseDelta < 60) opacity = 1 - (phaseDelta / 60) * 0.7
        $(this).css('opacity', opacity)
      })
    }, delayMs)
  })
}

// Scale the div
$.fn.scaleilax = function () {
  const $els = $(this)

  const update = () => {
    const height = $(window).height()
    const top = $(window).scrollTop()

    $els.each(function () {
      // Determine position
      const $el = $(this)
      const position = $el.offset().top - top
      const percentage = (position * 100) / height

      // Determine zones
      const zones = $el.attr('data-scaleilax').split(' ')
      const isTop = zones.includes('top')
      const isBottom = zones.includes('bottom')

      // Determine factor
      const scaleFactor =
        Number.parseFloat($el.attr('data-scaleilax-factor')) || 0.4

      // Run math
      const inZone = (isTop && percentage < 50) || (isBottom && percentage > 50)
      const scalePercentage = Math.abs(50 - percentage)
      const scale = Math.max(
        inZone ? 0 : 1,
        1 + (scalePercentage / 100) * (scaleFactor - 1),
      )
      $el.css('transform', 'scale(' + scale + ',' + scale + ')')
    })
  }
  update()

  const throttled = throttle(update, 10)
  $(window).scroll(throttled)
}

// Rotate something
$.fn.rotateilax = function () {
  const $els = $(this)

  const update = () => {
    const height = $(window).height()
    const top = $(window).scrollTop()

    // Icons
    $els.each(function () {
      const $el = $(this)
      const position = $el.offset().top - top
      const percentage = (position * 100) / height
      const degrees = Number.parseFloat($el.attr('data-rotateilax'))

      let rotation = 0
      if (percentage > 50)
        rotation = (Math.floor((percentage - 50) * 2) * degrees) / 100

      $el.css('transform', 'rotate(' + rotation + 'deg)')
    })
  }
  update()

  const throttled = throttle(update, 10)
  $(window).scroll(throttled)
}

// Fade-in something
$.fn.opacilax = function () {
  const $els = $(this)

  const update = () => {
    const height = $(window).height()
    const top = $(window).scrollTop()

    // Icons
    $els.each(function () {
      const $el = $(this)
      const position = $el.offset().top - top
      const percentage = (position * 100) / height
      const minimum = Number.parseFloat($el.attr('data-opacilax'))
      const range = 1 - minimum

      let opacity = 1
      if (percentage > 50)
        opacity = 1 - (Math.floor((percentage - 50) * 2) * range) / 100

      $el.css('opacity', opacity)
    })
  }
  update()

  const throttled = throttle(update, 10)
  $(window).scroll(throttled)
}

// Timeline
$.fn.timelineilax = function () {
  $(this).each(function () {
    const $listItems = $(this).find('.event')
    const $progressBar = $(this).find('.timeline-bar.active')

    const update = () => {
      const height = $(window).height()
      const center = height * 0.5
      const top = $(window).scrollTop()

      // Timeline progress bar
      const position = $progressBar.offset().top - (top + 10)
      if (center < position) $progressBar.height(0)
      else $progressBar.height(center - position)

      // List item markers
      const pastLi = []
      const futureLi = []
      const activeLi = []
      const inactiveLi = []
      $listItems.each(function () {
        const $listItem = $(this)
        const position = $listItem.offset().top - top

        const past = position <= center
        if (past) pastLi.push($listItem[0])
        else futureLi.push($listItem[0])

        const active = Math.abs(position - center) < 20
        if (active) activeLi.push($listItem[0])
        else inactiveLi.push($listItem[0])
      })
      $(pastLi).addClass('past').removeClass('future')
      $(futureLi).removeClass('past').addClass('future')
      $(activeLi).addClass('active')
      $(inactiveLi).removeClass('active')
    }
    update()

    const throttled = throttle(update, 10)
    $(window).scroll(throttled)
  })
}
