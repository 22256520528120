import { Tooltip } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { SignatureRecordedView } from '../signature'
import { initialState } from '../state'
const { handbook, person, session } = initialState

export const SignatureHeader = () => {
  const { signature } = handbook
  return (
    <div>
      This is the exact {handbook.name} that{' '}
      <strong>
        {session.person && signature.personId === session.person.id
          ? 'you'
          : person.fullName}
      </strong>{' '}
      <SignedByView /> on {moment(signature.signedAt).format('MMMM Do, YYYY')}.
    </div>
  )
}

const SignedByView = () => {
  const { signature } = handbook
  const { recordedByPerson } = signature
  if (!recordedByPerson) return <>signed</>

  return (
    <>
      <Tooltip
        content={
          <SignatureRecordedView person={person} signature={signature} />
        }
        maxWidth={240}
      >
        <u>signed</u>
      </Tooltip>

      <FontAwesomeIcon
        className='tw-text-red-500'
        icon={['far', 'asterisk']}
        style={{
          fontSize: 10,
          marginLeft: 2,
          transform: 'translateY(-6px)',
        }}
      />
    </>
  )
}
