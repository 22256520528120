import { colors, fontFamily, fontFamilySignature } from '@blissbook/ui/branding'
import { Alert, Field, Link, Modal } from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { signHandbook } from '../actions'
import { initialState, isLive } from '../state'
import { translate } from '../translate'
import { moreButtonText, readMoreEls } from '../widgets/read-more'
import { TemplateWysiwyg, useTemplateNode } from '../wysiwyg'
import { SignatureRecordedView } from './recorded'
const { handbook, person, user } = initialState
const { forceSign } = handbook.acknowledgementForm

const AcknowledgementBody = ({ onChange, ...props }) => {
  const [maxScrolled, setMaxScrolled] = useState(0)
  const [node, setNode] = useState()

  const updateMaxScrolled = () => {
    const top = node.scrollTop
    const height = node.offsetHeight
    const total = node.scrollHeight
    const ratio = (top + height) / total
    const scrolled = Math.floor(ratio * 100)
    if (scrolled > maxScrolled) {
      setMaxScrolled(scrolled)
      onChange(scrolled)
    }
  }

  useEffect(() => {
    if (!node) return
    updateMaxScrolled()
  }, [node])

  return (
    <TemplateWysiwyg
      {...props}
      onScroll={updateMaxScrolled}
      ref={setNode}
      tabIndex='-1'
      templateId='acknowledgement-body'
    />
  )
}

const initialValues = handbook.signature
  ? {
      readMore: true,
      signature: handbook.signature.signature,
    }
  : {
      readMore: false,
      scrolledPercentage: 0,
      signature: '',
    }

const readMoreTemplate = forceSign
  ? `I understand that any content accessible via a "{{moreButtonText}}" button within this document is covered by this acknowledgement.`
  : `I've opened all of the "{{moreButtonText}}" sections and understand the content within those sections is included in this acknowledgement.`

const readMoreText = translate(readMoreTemplate, { moreButtonText })

export const SignModal = Modal.wrap((props) => {
  const [, setTitleNode] = useTemplateNode('acknowledgement-title')
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setStatus }) => {
        setStatus({ isSubmitting: true })

        try {
          const { signature } = values
          await signHandbook(signature)
        } catch (error) {
          handleError(error, false)
          setStatus({ lastError: error })
        }
      }}
      validate={(values) => {
        const { readMore, scrolledPercentage, signature } = values
        const errors = {}

        if (readMoreEls.length > 0 && !readMore) {
          errors.readMore = 'Required'
        }

        if (scrolledPercentage < 100) {
          errors.scrolledPercentage = 'Required'
        }

        if (!signature.length) {
          errors.signature = 'Required'
        }

        return errors
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        setFieldValue,
        status: { isSubmitting, lastError } = {},
        touched,
        values,
      }) => (
        <Modal.Component
          {...props}
          className='acknowledgement full-screen-sm'
          position='center'
          width={750}
        >
          <Modal.Content>
            <Modal.Header hideCloseButton={forceSign || isSubmitting}>
              <Modal.Title ref={setTitleNode} />
            </Modal.Header>

            <Modal.Body>
              {touched.scrolledPercentage && errors.scrolledPercentage ? (
                <p className='tw-text-red-700'>
                  {translate(
                    'You must read (scroll to the bottom of) the entire acknowledgement in order to sign off on it.',
                  )}
                </p>
              ) : (
                <p className='tw-text-gray-500'>
                  {translate(
                    'Please read the following and sign at the bottom!',
                  )}{' '}
                  {values.readMore}
                </p>
              )}

              <form noValidate onSubmit={handleSubmit}>
                <AcknowledgementBody
                  className={classnames('body', {
                    'has-error':
                      touched.scrolledPercentage && errors.scrolledPercentage,
                  })}
                  css={{ fontSize: 14 }}
                  onChange={(value) =>
                    setFieldValue('scrolledPercentage', value)
                  }
                />

                {lastError && (
                  <Alert type='danger' variant='large'>
                    {translate(
                      `There was an unexpected error submitting your signature. Please refresh the page and try again in a few minutes. If that doesn't help, please contact your HR representative`,
                    )}
                  </Alert>
                )}

                <fieldset disabled={handbook.signature || isSubmitting}>
                  {readMoreEls.length > 0 && (
                    <Field
                      type='checkbox'
                      className='tw-my-2'
                      label={readMoreText}
                      name='readMore'
                      size='sm'
                    />
                  )}

                  {handbook.signature?.recordedByPerson ? (
                    <div
                      className='tw-flex tw-items-center tw-mt-4 tw-p-4 tw-bg-gray-100'
                      style={{ borderRadius: 4 }}
                    >
                      <FontAwesomeIcon
                        className='tw-mr-4'
                        icon={['far', 'asterisk']}
                        style={{
                          color: colors['red-700'],
                          fontSize: 24,
                        }}
                      />

                      <SignatureRecordedView
                        person={person}
                        signature={handbook.signature}
                        style={{ fontSize: 14 }}
                      />
                    </div>
                  ) : (
                    <>
                      <div css={{ position: 'relative' }}>
                        <div
                          css={{
                            borderBottom: '1px solid',
                            borderColor:
                              touched.signature && errors.signature
                                ? colors['red-500']
                                : colors['gray-400'],
                            bottom: 5,
                            left: 0,
                            position: 'absolute',
                            width: '100%',
                          }}
                        />

                        <input
                          id='signature'
                          type='text'
                          css={{
                            background: 'none',
                            border: 'none',
                            fontFamily: fontFamilySignature,
                            fontSize: 36,
                            outline: 'none',
                            position: 'relative',
                            width: '100%',
                            '&::placeholder': {
                              color:
                                touched.signature && errors.signature
                                  ? colors['red-500']
                                  : colors['gray-400'],
                              fontFamily,
                              fontSize: 24,
                              fontStyle: 'italic',
                              fontWeight: 300,
                              padding: '6px 0',
                            },
                          }}
                          name='signature'
                          placeholder={translate(
                            'Sign here by typing your name',
                          )}
                          value={values.signature}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      {person && (
                        <div
                          className='tw-text-gray-500'
                          css={{ fontSize: 12, fontStyle: 'italic' }}
                        >
                          {translate(
                            'Note: You are signing as {{authId}}. Not you?',
                            {
                              authId: [
                                person.fullName,
                                user && `(${user.authId})`,
                              ]
                                .filter(Boolean)
                                .join(' '),
                            },
                          )}{' '}
                          <a
                            href='/sign-out'
                            css={{ pointerEvents: isLive ? 'all' : 'none' }}
                          >
                            {translate('Sign out.')}
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </fieldset>

                {!handbook.signature?.recordedByPerson && (
                  <div className='form-submit tw-mt-4'>
                    <button
                      type='submit'
                      children={translate('Sign')}
                      className='btn btn-dark sign'
                      css={{ flex: 1 }}
                      disabled={handbook.signature || isSubmitting}
                    />

                    {!forceSign && !isSubmitting && (
                      <button
                        type='button'
                        children={translate('Cancel')}
                        className='btn btn-secondary tw-ml-2'
                        onClick={props.onClose}
                      />
                    )}
                  </div>
                )}
              </form>
            </Modal.Body>
          </Modal.Content>
        </Modal.Component>
      )}
    </Formik>
  )
})

export const SignMoreModal = Modal.wrap((props) => {
  const handbooks = useStore('otherHandbooks')
  const unsignedHandbooks = handbooks.filter((handbook) =>
    handbook.permissionIds.includes('handbook.sign'),
  )

  return (
    <Modal.Component {...props} className='sign-more' position='center'>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{translate(`There's More to Sign!`)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {unsignedHandbooks.map((handbook, index) => (
            <div
              className={classnames(
                'tw-flex tw-items-center',
                index ? 'tw-mt-2' : 'tw-mt-0',
              )}
              key={handbook.id}
            >
              <p className='tw-m-0 tw-mr-2 ellipsis' title={handbook.name}>
                {handbook.name}
              </p>
              <Link
                className='tw-flex tw-items-center tw-m-0 view-and-sign-link'
                href={handbook.path}
                style={{
                  width: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                {translate('View and Sign')}
                <FontAwesomeIcon
                  className='tw-m-0 tw-ml-1'
                  icon={['far', 'arrow-right']}
                  style={{
                    color: '#28AAE2',
                    width: 9,
                    height: 10,
                  }}
                />
              </Link>
            </div>
          ))}
        </Modal.Body>
      </Modal.Content>
    </Modal.Component>
  )
})
