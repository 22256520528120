import { replaceText } from '@blissbook/lib/document/text'
import type { Translations } from '@blissbook/lib/types'
import { ChangeIcon } from '@blissbook/ui/lib/icons'
import { initialState } from './state'
const { handbook } = initialState

type TemplateParams = Record<string, string>

export class Translator {
  private translations: Translations

  constructor(translations: Translations) {
    this.translations = translations
  }

  isTranslation(source: string) {
    return this.translations[source] !== undefined
  }

  translate(source: string, params?: TemplateParams) {
    const text = this.translations[source] || source
    return replaceText(text, params)
  }
}

// Build the translators we are presented
const translationsByLanguageCode = window.__TRANSLATIONS__ || {}
const translatorsByLangageCode = new Map<string, Translator>()
for (const [languageCode, translations] of Object.entries(
  translationsByLanguageCode,
)) {
  const translator = new Translator(translations)
  translatorsByLangageCode.set(languageCode, translator)
}

// Get the translator for this language
export function getTranslator(languageCode: string) {
  return translatorsByLangageCode.get(languageCode) || new Translator({})
}

// Translator for the current content
const handbookTranslator = getTranslator(handbook.languageCode)

export function isTranslation(source: string) {
  return handbookTranslator.isTranslation(source)
}

export function translate(source: string, params?: TemplateParams) {
  return handbookTranslator.translate(source, params)
}

export function renderTranslation(source: string, params?: TemplateParams) {
  const text = translate(source, params)
  return text.split('✦').flatMap((text, index) => {
    if (index === 0) return text
    return [
      <ChangeIcon
        key={index}
        size={14}
        style={{ transform: 'translateY(-2px)' }}
      />,
      text,
    ]
  })
}
