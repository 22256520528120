import type { Annotation } from '@blissbook/lib/blissbook'
import moment from 'moment'

export function AnnotationView({
  annotation,
  className,
}: {
  annotation: Annotation
  className?: string
}) {
  const publishedAt = moment(annotation.publishedAt)
  return (
    <div className={className}>
      <div
        className='tw-text-black tw-whitespace-pre-wrap rw-wysiwyg tw-mb-1'
        // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
        dangerouslySetInnerHTML={{ __html: annotation.html }}
      />
      <div className='tw-text-gray-500'>
        {publishedAt.format('MMM D, YYYY')}, {publishedAt.format('h:mm a')}
      </div>
    </div>
  )
}
