import { colors } from '@blissbook/ui/branding'
import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import { SearchInput } from '@blissbook/ui/lib/input'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import { setPrintOpen } from './actions'
import { initialState } from './state'
import { translate } from './translate'
const { printUrl, toc } = initialState.handbook
const basePrintUrl = printUrl.split('?')[0]
const width = 380

// Determine the print URL for the current location
function getPrintUrl(location, options = {}) {
  const { extension = '' } = options
  const query = qs.parse(location.search)
  const search = qs.stringify({ ...query, ...options.query })
  return search ? `${basePrintUrl}${extension}?${search}` : basePrintUrl
}

function getTocOptions(location) {
  return toc.map((item) => {
    const { documentId, sectionId } = item

    // Document
    if (documentId) {
      return {
        key: item.bookmark,
        printUrl: getPrintUrl(location, {
          extension: '.pdf',
          query: { document: documentId },
        }),
        label: item.title,
      }
    }

    // Section
    const chapter = toc.find((c) => c.sectionId === item.chapterId)
    return {
      key: item.bookmark,
      printUrl: getPrintUrl(location, {
        extension: '.pdf',
        query: { section: sectionId },
      }),
      label: item.title,
      tooltip: chapter ? `In: ${chapter.title}` : undefined,
    }
  })
}

export default () => {
  // Current state
  const location = useLocation()
  const isOpen = useStore('isPrintOpen')
  const printUrl = getPrintUrl(location)
  const tocOptions = getTocOptions(location)

  // Search
  const [search, setSearch] = useState()
  useEffect(() => {
    if (!isOpen) setSearch(undefined)
  }, [isOpen])

  // Close on click outside
  const [node, setNode] = useState()
  useMouseDownOutside(() => setPrintOpen(false), [node])

  return createPortal(
    <aside
      className='print tw-pt-6 tw-pb-8 tw-px-8'
      css={{
        background: 'white',
        bottom: 0,
        position: 'fixed',
        top: 0,
        transition: 'all 250ms linear',
        width,
        zIndex: 1050,
      }}
      ref={setNode}
      style={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? undefined : 'none',
        right: isOpen ? 0 : -width,
      }}
    >
      <div className='tw-flex tw-justify-end tw-mb-2'>
        <button
          type='button'
          className='btn-icon btn-close'
          css={{ fontSize: 18 }}
          onClick={() => setPrintOpen(false)}
          aria-label={translate('Close Menu')}
        >
          <FontAwesomeIcon icon='times' />
        </button>
      </div>
      <header className='tw-flex tw-items-start tw-justify-between tw-mb-8'>
        <h2 className='tw-my-0'>
          {translate('What would you like to print?')}
        </h2>
      </header>
      <a
        className='btn btn-dark tw-flex tw-items-center tw-justify-center'
        href={printUrl}
        onClick={() => setPrintOpen(false)}
        rel='noopener noreferrer'
        target='_blank'
      >
        <FontAwesomeIcon className='tw-mr-2' icon={['far', 'print']} />
        {translate('Print Everything')}
      </a>

      <div
        className='tw-flex tw-items-center tw-justify-center tw-text-gray-300 tw-mt-6 tw-mb-4'
        css={{ position: 'relative' }}
      >
        <div
          css={{
            borderTop: `2px solid ${colors['gray-200']}`,
            position: 'absolute',
            marginTop: -1,
            top: '50%',
            width: 240,
            zIndex: -1,
          }}
        />
        <div
          className='tw-px-2 text-center tw-uppercase'
          css={{
            background: 'white',
            maxWidth: 200,
          }}
        >
          {translate('or')}
        </div>
      </div>

      <label htmlFor='print-section-search'>
        {translate('Print a specific section/policy:')}
      </label>
      <SearchInput
        getOptions={tocOptions}
        onChange={(_event, value) => setSearch(value)}
        onSelect={(option) => {
          const { printUrl } = option
          window.open(printUrl, '_blank', 'noopener noreferrer')
          setPrintOpen(false)
        }}
        placeholder={translate('ex.: Holidays')}
        size='sm'
        inputId='print-section-search'
        value={search}
      />
    </aside>,
    document.body,
  )
}
