import { useStore } from '@blissbook/ui/util/store'
import { useEffect } from 'react'
import { setSignOpen } from '../actions'
import { translate } from '../translate'

export const SignHeader = () => {
  const handbook = useStore('handbook')
  const hasAnnotations = handbook.annotations.length > 0

  useEffect(() => {
    document.body.classList.add('-acknowledge')
    return () => {
      document.body.classList.remove('-acknowledge')
    }
  })

  return (
    <header className='acknowledge'>
      <div className='acknowledge-receipt tw-hidden lg:tw-block'>
        {translate('Acknowledge your receipt of the {{handbookName}}:', {
          handbookName: handbook.name,
        })}
      </div>

      <button
        type='button'
        className='tw-float-right btn btn-red sign tw-hidden lg:tw-block'
        onClick={() => setSignOpen(true)}
      >
        {translate('Sign It')}
      </button>

      {!hasAnnotations && (
        // Leave hidden-lg for custom handbookCss
        <button
          type='button'
          className='btn btn-red tw-block lg:tw-hidden hidden-lg sign'
          onClick={() => setSignOpen(true)}
        >
          {translate('Sign Your Acknowledgement')}
        </button>
      )}
    </header>
  )
}
