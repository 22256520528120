import type { ResolvedAnnotation } from '@blissbook/lib/blissbook'
import { scrollToElement, scrollToHash } from '@blissbook/ui/util/scroll'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { openReadMore } from '../../widgets/read-more'
import { useAnnotationsContext } from '../AnnotationsContext'
import type { AnnotationsGroup } from './useAnnotationGroups'

const ACTIVE_CLASS = 'annotation-active'
const HIGHLIGHT_CLASS = 'annotation-highlight'

function highlightAnnotation(annotation: ResolvedAnnotation) {
  const annotationElement = document.querySelector(
    `[data-annotation-id="${annotation.id}"]`,
  )
  annotationElement?.classList.add(HIGHLIGHT_CLASS)
}

function highlightAnnotations(annotationGroups: AnnotationsGroup[]) {
  // Remove any existing highlights
  document.querySelectorAll(`.${HIGHLIGHT_CLASS}`).forEach((el) => {
    el.classList.remove(HIGHLIGHT_CLASS)
  })

  // Add the new highlights
  for (const group of annotationGroups) {
    group.annotations.forEach(highlightAnnotation)
  }
}

export type AnnotationsState = {
  annotationId: string | undefined
  annotationGroup: AnnotationsGroup | undefined
  isMaximized: boolean
  isMinimized: boolean
  onMaximize: () => void
  onMinimize: () => void
  setAnnotationGroup: (annotationGroup: AnnotationsGroup | undefined) => void
  setAnnotationId: (annotationId: string | undefined) => void
  setMaximized: (isMaximized: boolean) => void
  setMinimized: (isMinimized: boolean) => void
  sizeClassName: string
}

export function useAnnotationsState(): AnnotationsState {
  const { annotationGroups, annotationGroupsByAnnotationId } =
    useAnnotationsContext()
  const [annotationId, setAnnotationId] = useState<string>()
  const [isMaximized, setMaximized] = useState(false)
  const [isMinimized, setMinimized] = useState(false)

  const annotationGroup = annotationGroupsByAnnotationId.get(annotationId)

  const sizeClassName = classnames({
    '-maximized': isMaximized,
    '-minimized': isMinimized,
  })

  function onMaximize() {
    setMaximized(!isMaximized)
    setMinimized(false)
  }

  function onMinimize() {
    setMaximized(false)
    setMinimized(!isMinimized)
  }

  function setAnnotationGroup(annotationGroup: AnnotationsGroup | undefined) {
    if (!annotationGroup) {
      setAnnotationId(undefined)
      return
    }

    const [annotation] = annotationGroup.annotations
    setAnnotationId(annotation.id)
    setMinimized(false)
  }

  // Highlight annotations
  useEffect(() => {
    highlightAnnotations(annotationGroups)
  }, [annotationGroups])

  // Active annotation element
  useEffect(() => {
    if (!annotationGroup) return

    const delay = 250
    const { bookmark } = annotationGroup
    const annotationEl = document.querySelector<HTMLElement>(
      `span[data-annotation-id="${annotationId}"]`,
    )
    if (annotationEl) {
      annotationEl.classList.add(ACTIVE_CLASS)
      scrollToElement(annotationEl, { delay, offset: -125 })
    } else {
      scrollToHash(bookmark, { delay })
    }

    openReadMore(bookmark)

    return () => {
      if (annotationEl) {
        annotationEl.classList.remove(ACTIVE_CLASS)
      }
    }
  }, [annotationId, annotationGroup])

  return {
    annotationId,
    annotationGroup,
    isMaximized,
    isMinimized,
    onMaximize,
    onMinimize,
    setAnnotationGroup,
    setAnnotationId,
    setMaximized,
    setMinimized,
    sizeClassName,
  }
}
