import './index.scss'
import config from '@blissbook/ui-config'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { scrollToHashAndReplace } from '@blissbook/ui/util/scroll'
import qs from 'query-string'
import { Fragment, useEffect, useState } from 'react'
import { MobileLanguageMenu } from '../language'
import {
  DesktopSearchBar,
  MobileSearchBar,
  type SearchState,
  useSearchState,
} from '../search'
import { isDesktop } from '../viewport'
import { Menu } from './menu'
import { MobileNav } from './mobile'
import { Toc } from './toc'
const { env } = config

$(() => {
  // Scroll to the hash in the query parameter, if the hash got stripped off
  const { hash, search } = window.location
  const query = qs.parse(search)
  if (!hash && query.anchor) {
    scrollToHashAndReplace(query.anchor).catch(logUIError)
  }

  // Handle clicks within the handbook
  $('article').on('click', 'a[href^="#"]', function (e) {
    e.preventDefault()

    const hash = $(this).attr('href').substring(1)
    scrollToHashAndReplace(hash, { delay: 500, setQuery: true }).catch(
      logUIError,
    )
  })
})

function useTocState({
  isLocked,
  searchState,
}: {
  isLocked?: boolean
  searchState: SearchState
}) {
  const [isTocLocked, setTocLocked] = useState(
    isDesktop && !searchState.search && !env.test,
  )
  const [isTocOpen, setTocOpen] = useState(false)

  // Unlock the ToC a bit after the navigation has unlocked
  useEffect(() => {
    if (isLocked) return

    const timerId = setTimeout(() => setTocLocked(false), 2000)

    return () => {
      clearTimeout(timerId)
    }
  }, [isLocked])

  const onChangeTocOpen = (isOpen: boolean) => {
    setTocLocked(false)
    setTocOpen(isOpen)
  }

  return {
    isTocLocked,
    isTocOpen: isTocLocked || isTocOpen,
    setTocOpen: isLocked ? undefined : onChangeTocOpen,
  }
}
export const Navigation = ({ isLocked }: { isLocked?: boolean }) => {
  const searchState = useSearchState()
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isMobileSearchOpen, setMobileSearchOpen] = useState(
    !!searchState.search,
  )
  const { isTocLocked, isTocOpen, setTocOpen } = useTocState({
    isLocked,
    searchState,
  })

  const props = {
    setLanguageMenuOpen,
    setMenuOpen,
    setTocOpen,
    setMobileSearchOpen,
  }

  return (
    <Fragment>
      <DesktopSearchBar
        {...props}
        isOpen={isTocLocked || !isTocOpen}
        state={searchState}
        isTocOpen={isTocOpen}
      />

      <MobileLanguageMenu {...props} isOpen={isLanguageMenuOpen} />
      {/* @ts-ignore: better types */}
      <Menu {...props} isOpen={isMenuOpen} />

      {isMobileSearchOpen ? (
        <MobileSearchBar {...props} state={searchState} />
      ) : (
        <MobileNav
          {...props}
          isOpen={!isLanguageMenuOpen && !isMenuOpen && !isTocOpen}
        />
      )}

      {/* @ts-ignore: better types */}
      <Toc {...props} isOpen={isTocOpen} />
    </Fragment>
  )
}
