import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import { Button } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useState } from 'react'
import {
  setHelpOpen,
  setPrintOpen,
  setSignHistoryOpen,
  setSignOpen,
} from '../actions'
import { translate } from '../translate'

import { getPreferredName } from '@blissbook/lib/name'

export const Menu = React.memo((props) => {
  const { isOpen, setMenuOpen, setMobileSearchOpen } = props
  const [node, setNode] = useState()
  const featureFlagIds = useStore('featureFlagIds')
  const isSignatureRequired = useStore('isSignatureRequired')
  const handbook = useStore('handbook')
  const person = useStore('person')
  const session = useStore('session')

  const canAdmin = [session.superUser, person?.isOrgCollaborator].some(Boolean)

  // Close on click outside
  useMouseDownOutside(() => setMenuOpen(false), [node])

  const maxMenuTextLength = 6
  const menuText = translate('Menu')

  return (
    <nav
      className={classnames('menu below-header', { '-open': isOpen })}
      ref={setNode}
    >
      <header className='tw-flex lg:tw-hidden tw-items-center tw-justify-between'>
        <Button
          className='tw-flex tw-items-center btn-icon nav-close'
          color='gray-600'
          onClick={() => setMenuOpen(false)}
          title={translate('Close Menu')}
        >
          <FontAwesomeIcon icon={['far', 'bars']} />
          <FontAwesomeIcon
            icon='angle-right'
            style={{ fontSize: 12, marginLeft: 3 }}
          />
        </Button>
        {person && (
          <h4 className='ellipsis text-right' title={person.firstName}>
            {person.firstName}
          </h4>
        )}
      </header>

      <div className='tw-hidden lg:tw-block menu-items placeholder'>
        <MenuItem
          className='-large'
          icon={menuText.length > maxMenuTextLength ? 'ellipsis-h' : undefined}
          iconText={menuText.length > maxMenuTextLength ? undefined : menuText}
          label={
            person &&
            translate('Hi {{firstName}}!', {
              firstName: getPreferredName(person),
            })
          }
        />
      </div>
      <div className='menu-items'>
        <MenuItem
          className='menu-help -large'
          icon='question'
          label={translate('Get Help')}
          onClick={() => {
            setHelpOpen(true)
            setMenuOpen(false)
          }}
        />

        <MenuItem
          className='tw-flex lg:tw-hidden menu-search'
          icon='search'
          label={translate('Search')}
          onClick={() => {
            setMobileSearchOpen(true)
            setMenuOpen(false)
          }}
        />

        {(canAdmin || !handbook.isPrintDisabled) && (
          <MenuItem
            className='menu-print'
            icon='print'
            label={translate('Print to PDF')}
            onClick={() => {
              setPrintOpen(true)
              setMenuOpen(false)
            }}
          />
        )}

        {canAdmin ? (
          <MenuItem
            className='tw-hidden lg:tw-flex menu-edit'
            icon='home'
            href='/home'
            label={translate('Admin Dashboard')}
          />
        ) : handbook.permissionIds.includes('handbook.draft.edit') ? (
          <MenuItem
            className='tw-hidden lg:tw-flex menu-edit'
            icon='pencil-alt'
            href={`${handbook.handbookUrl}/edit`}
            label={translate('Edit')}
          />
        ) : null}

        {featureFlagIds.includes('allowManagers') && person?.isManager && (
          <MenuItem
            className='tw-flex menu-manager'
            icon='users'
            href='/manager'
            label={translate('Manager Dashboard')}
          />
        )}

        {!handbook.isPublic && (
          <>
            {handbook.signatures.length > 0 && (
              <MenuItem
                className='menu-history'
                icon='clock-rotate-left'
                iconStyle={{ transform: 'translateX(1px)' }}
                label={translate('View Your Signature History')}
                onClick={() => {
                  setSignHistoryOpen(true)
                  setMenuOpen(false)
                }}
              />
            )}

            {isSignatureRequired && (
              <MenuItem
                className='menu-acknowledge -danger'
                icon='signature'
                iconStyle={{ transform: 'translateX(1px)' }}
                label={translate('Confirm Receipt of Document')}
                onClick={() => {
                  setSignOpen(true)
                  setMenuOpen(false)
                }}
              />
            )}
          </>
        )}

        {session.user && (
          <MenuItem
            className='menu-sign-out'
            href='/sign-out'
            icon='sign-out'
            iconStyle={{ transform: 'translateX(1px)' }}
            label={translate('Sign Out')}
          />
        )}
      </div>
    </nav>
  )
})

const getMenuItemComponent = ({ href, onClick }) => {
  if (href) return 'a'
  if (onClick) return 'button'
  return 'div'
}

const MenuItem = ({
  className,
  icon,
  iconStyle,
  iconText,
  label,
  ...props
}) => {
  const Component = getMenuItemComponent(props)
  return (
    <Component {...props} className={classnames('menu-item', className)}>
      {label && <span className='tw-text-sm'>{label}</span>}

      <div className='menu-bubble-container'>
        <div className='menu-bubble tw-flex tw-items-center tw-justify-center'>
          {icon && <FontAwesomeIcon icon={icon} style={iconStyle} />}
          {iconText}
        </div>
      </div>
    </Component>
  )
}
