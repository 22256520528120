import { ScrollContainer } from '@blissbook/ui/lib/scroll'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import type React from 'react'
import type { AnnotationsState } from './hooks'

export const AnnotationsHeader = ({
  children,
  className,
  state: { isMaximized, isMinimized, onMaximize, onMinimize },
  style,
}: {
  children: React.ReactNode
  className?: string
  state: AnnotationsState
  style?: React.CSSProperties
}) => {
  return (
    <header
      className={classnames(
        'tw-flex tw-items-center tw-justify-between',
        className,
      )}
      style={style}
    >
      {children}
      <div className='tw-flex tw-items-center -mobile'>
        <AnnotationsHeaderButton
          icon={['far', 'window-minimize']}
          isActive={isMinimized}
          onClick={onMinimize}
          title='Minimize'
        />
        <AnnotationsHeaderButton
          icon={['far', 'window-maximize']}
          isActive={isMaximized}
          onClick={onMaximize}
          title='Maximize'
        />
      </div>
    </header>
  )
}

const AnnotationsHeaderButton = ({
  className,
  icon,
  isActive,
  ...props
}: {
  className?: string
  icon: IconProp
  isActive: boolean
  onClick: () => void
  title: string
}) => (
  <button
    {...props}
    type='button'
    className={classnames('btn-icon', { '-active': isActive }, className)}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
)

export const AnnotationsBody = ({
  children,
  className,
  state: { isMaximized, isMinimized },
  style,
}: {
  children: React.ReactNode
  className?: string
  state: AnnotationsState
  style?: React.CSSProperties
}) => (
  <main
    className={className}
    style={{
      ...style,
      display: isMinimized ? 'none' : undefined,
      flex: isMaximized ? 1 : undefined,
      minHeight: 0,
    }}
  >
    <ScrollContainer
      children={children}
      height={isMaximized ? '100%' : undefined}
      maxHeight={isMaximized ? undefined : 240}
      showScrollText
    />
  </main>
)

export const AnnotationModal = ({
  className,
  ...props
}: {
  children: React.ReactNode
  className?: string
  id?: string
}) => (
  <div
    {...props}
    className={classnames('modal-dialog -annotation', className)}
  />
)
