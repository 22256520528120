import { Button } from '@blissbook/ui/lib'
import { Field, Formik } from 'formik'
import type React from 'react'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { translate } from '../translate'
import { SearchIconButton, SearchResultInput } from './input'
import type { SearchState } from './state'
import './mobile.scss'

export const MobileSearchBar: React.FC<{
  setMobileSearchOpen: (isOpen: boolean) => void
  state: SearchState
}> = ({ setMobileSearchOpen, state }) => {
  const { onChangeSearch, search } = state
  const [inputNode, setInputNpde] = useState<HTMLInputElement>()
  const [showForm, setShowForm] = useState(!search)

  useEffect(() => {
    if (!inputNode) return

    if (search) {
      inputNode.blur()
    } else {
      inputNode.focus()
    }
  }, [inputNode, search])

  const onClose = () => {
    onChangeSearch(null)
    setMobileSearchOpen(false)
  }

  return (
    <div className='tw-flex lg:tw-hidden tw-items-center' id='mobile-search'>
      <SearchIconButton
        className='tw-mr-1'
        icon='arrow-left'
        onClick={onClose}
        style={{ fontSize: 12 }}
      />

      <Formik
        enableReinitialize
        initialValues={{
          search: search || '',
        }}
        onSubmit={(values) => {
          onChangeSearch(values.search)
          setShowForm(false)
        }}
        validationSchema={Yup.object().shape({
          search: Yup.string().required(),
        })}
      >
        {({ handleSubmit }) => (
          <form
            className='tw-flex tw-items-center'
            noValidate
            onSubmit={handleSubmit}
            style={{ flex: 1, minWidth: 0 }}
          >
            <Field
              className='form-control form-control-lg'
              name='search'
              onFocus={() => {
                if (!showForm) setShowForm(true)
              }}
              placeholder={`${translate('Search')}...`}
              innerRef={setInputNpde}
              style={{ flex: 1, minWidth: 0 }}
            />

            {showForm ? (
              <Button color='gray-600' size='sm' type='submit'>
                {translate('Search')}
              </Button>
            ) : (
              <SearchResultInput
                noResults={translate('No results found.')}
                state={state}
              />
            )}
          </form>
        )}
      </Formik>
    </div>
  )
}
