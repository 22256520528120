import './vendor'
import '@blissbook/ui/util/date'
import './styles'

import { ApolloProvider } from '@apollo/client'
import { ErrorBoundary } from '@blissbook/ui/lib/error'

import { client as apolloClient } from '@blissbook/ui/util/apollo'
import { history } from '@blissbook/ui/util/history'
import { StoreProvider } from '@blissbook/ui/util/store'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import Application from './application'
import { renderEffects } from './effects'
import { store } from './store'

import { initializeSentry } from '@blissbook/ui/util/integrations/sentry'

// initialize sentry
initializeSentry()

const onLoaded = () => {
  const loadNode = document.getElementById('loading')
  const style = window.getComputedStyle(loadNode)
  const transitionDelay = Number.parseFloat(style.transitionDuration) * 1000
  loadNode.classList.add('-loaded')
  setTimeout(() => {
    document.body.removeChild(loadNode)
  }, transitionDelay)
}

// Wait for everything to be ready before removing the loading screen
$(() => {
  // Render handbook effects
  renderEffects()

  // Render the react components
  ReactDOM.render(
    <ErrorBoundary>
      <StoreProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <Router history={history}>
            <Application />
          </Router>
        </ApolloProvider>
      </StoreProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
    onLoaded,
  )
})
