import { gql } from '@apollo/client'
import HandbookSignature from '@blissbook/common/handbook/signature'
import type { MutationSignHandbookSessionArgs } from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

const DISMISS_ANNOTATIONS = gql`
  mutation dismissAnnotations($annotationIds: [ID!]!) {
    dismissAnnotations(annotationIds: $annotationIds)
  }
`

export async function dismissAnnotations(annotationIds: string[]) {
  await client.mutate({
    mutation: DISMISS_ANNOTATIONS,
    variables: { annotationIds },
  })
}

const SIGN_HANDBOOK_SESSION = gql`
  mutation signHandbookSession(
    $handbookId: Int!
    $handbookSessionId: Int!
    $input: SignHandbookSessionInput!
    $signatureRoundId: Int!
  ) {
    signature: signHandbookSession(
      handbookId: $handbookId
      handbookSessionId: $handbookSessionId
      input: $input
      signatureRoundId: $signatureRoundId
    ) {
      id
      handbookVersion
      signature
      signedAt
      createdAt
    }
  }
`

export async function signHandbookSession(
  variables: MutationSignHandbookSessionArgs,
) {
  const { data } = await client.mutate({
    mutation: SIGN_HANDBOOK_SESSION,
    variables,
  })

  return HandbookSignature.fromJSON(data.signature)
}
