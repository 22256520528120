import { Button, Modal } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import orderBy from 'lodash/orderBy'
import pluralize from 'pluralize'
import { setSignOpen } from '../actions'
import { isTranslation, translate } from '../translate'

export const SignHistoryModal = Modal.wrap(({ onClose }) => {
  const isSignatureRequired = useStore('isSignatureRequired')
  const handbook = useStore('handbook')
  const signatures = orderBy(handbook.signatures, 'id', 'desc')
  return (
    <Modal.Component
      className='signature-history'
      onClose={onClose}
      position='center'
      width={400}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{translate('Signature History')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='tw-text-gray-500'>
            {translate(
              `You've acknowledged your receipt of the {{handbookName}} ${
                handbook.signatures.length === 1 ? 'one' : '{{signaturesCount}}'
              } ${pluralize('time', handbook.signatures.length)}.`,
              {
                handbookName: handbook.name,
                signaturesCount: handbook.signatures.length,
              },
            )}
          </p>

          <div style={{ fontSize: 14 }}>
            {isSignatureRequired && (
              <div className='tw-flex tw-items-center tw-justify-between tw-mb-4'>
                <div
                  className='text-left tw-text-gray-500'
                  style={{ fontWeight: 'bold' }}
                >
                  {translate('Version {{version}}:', {
                    version: handbook.version,
                  })}
                </div>

                <Button
                  color='red-500'
                  onClick={() => {
                    onClose()
                    setSignOpen(true)
                  }}
                  size='sm'
                  style={{ textTransform: 'uppercase' }}
                >
                  {translate('Sign It')}
                </Button>
              </div>
            )}

            {signatures.map((signature) => (
              <div
                key={signature.id}
                className='tw-flex tw-items-center tw-justify-between tw-mb-4'
              >
                <div
                  className='text-left tw-text-gray-500'
                  style={{ fontWeight: 'bold' }}
                >
                  {translate('Version {{version}}:', {
                    version: signature.handbookVersion,
                  })}
                </div>

                <a
                  href={`/d/${handbook.id}-${handbook.slug}/signatures/${signature.id}/print.pdf?section=acknowledgement`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {signature.signedAt.format('YYYY-MM-DD H:mm')}
                </a>
              </div>
            ))}
          </div>

          <div className='form-submit tw-justify-center tw-mt-6'>
            <Button color='dark' onClick={onClose} size='sm'>
              {isTranslation('Okay') ? translate('Okay') : 'OK'}
            </Button>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Component>
  )
})
