import { Modal } from '@blissbook/ui/lib'
import { addInactivityListener } from '@blissbook/ui/util/inactivity'
import { useStore } from '@blissbook/ui/util/store'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'
import { startAutoSignOut } from './actions'
import { initialState, isPreview } from './state'
const { kioskMode } = initialState

export const AutoSignOutModal = Modal.wrap((props) => {
  const { onClose } = props
  const reason = useStore('autoSignOut')
  const [timeLeft, setTimeLeft] = useState(kioskMode.timeoutSeconds)

  // Sing out handler
  const onSignOut = () => {
    window.location.href = `/sign-out?reason=${reason}`
  }

  // Countdown timer
  useEffect(() => {
    // All done?
    if (timeLeft === 0) {
      if (!isPreview) onSignOut()
      return
    }

    // Move to the next tick
    const timerId = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)
    return () => clearTimeout(timerId)
  }, [timeLeft])

  return (
    <Modal.Component
      {...props}
      position='center'
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      width={480}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Do you want to continue your session?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='tw-text-gray-500'>
            For security reasons, your session will time out in{' '}
            {pluralize('second', timeLeft, true)}.
          </p>

          <div className='tw-flex tw-items-center tw-justify-center tw-mt-4'>
            <button
              type='button'
              children='Stay Signed In and Continue Session'
              className='btn btn-dark'
              css={{ flex: 1 }}
              onClick={onClose}
            />

            {!isPreview && (
              <button
                type='button'
                children='Sign Out Now'
                className='btn btn-secondary tw-ml-2'
                onClick={onSignOut}
              />
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Component>
  )
})

// Kick off the kiosk mode timer, if necessary
if (kioskMode) {
  addInactivityListener(() => {
    startAutoSignOut('inactivity')
  }, kioskMode.inactivitySeconds * 1000)
}
